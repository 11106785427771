.flex-dir-col{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.hidden{
    display: none;
}

.app{
    display: flex;
    flex-direction: row;
}