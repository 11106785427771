.add-button{
    border-radius: 10px;
    position: absolute;
    top: 30px;
    left: 20px;
}

.add-button:hover{
    cursor: pointer;
}

Table{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}




