.menuFolder-container{
    padding: 20px 0 20px 0;
    width: 100%;
    text-align: center;
    transition: all 0.5s;
    position: relative;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.menuFolder-container:hover{
    background-color: #7f7f7f;
    cursor: pointer;
}

.title{
    width: 80%;
}

.counter{
    box-sizing: border-box;
    padding: 0 10px;
}

.img-menu{
    max-width: 60px;
    height: auto;
}

.menuFolder-container:has(img){
    padding: 5px 0 5px 0;
    width: 100%;
    transition: all 0.5s;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}