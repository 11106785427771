.pagination-container{
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
}

.pagination-container button{
    min-width: 50px;
    min-height: 50px;
}

.num{
    font-size: 30px;
}