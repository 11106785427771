.form-container{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn-close-form{
    position: absolute;
    top: 10px;
    right: 10px;
}

.btn-salva{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.btn-salva:hover{
    transform: translateX(-50%) translateY(5px);
    box-shadow: none;
}

