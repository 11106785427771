.menu-container{
    height: 100vh;
    position: relative;
    top: 0;
    background-color: #aeaeae;
    width: 30%;
    max-width: 270px;
    transition: all 0.3s cubic-bezier(.215, .61, .355, 1);
}

.halfview{
    height: 100vh;
    position: relative;
    top: 0;
    background-color: #aeaeae;
    width: 30%;
    max-width: 100px;
    transition: all 0.3s cubic-bezier(.215, .61, .355, 1);
}

ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 999;
}

.separator{
    width: 80%;
    border: 1px solid white;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
}

.toogle{
    width: 10vh;
    height: 12vh;
    border-radius: 100%;
    background-color: #aeaeae;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(40%, -50%);
    z-index: 1;
    display: flex;
    justify-content: right;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.215, .61, .355, 1);
}

.toogle:hover{
    transform: translate(50%, -50%);
}

.logo{
    background-color: rgba(0, 0, 255, 0.582);
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 42px;
    color: white;
    white-space: nowrap;
    width: 100%;
    max-width: 270px;
}