.top-bar-container {
    width: 100%;
    position: relative;
    top: 0;
    background-color: #aeaeae;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.title-page {
    width: 100%;
}



.punti-vivieco{
    white-space: nowrap;
}