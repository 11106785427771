.input-container{
    margin: 10px;
    text-align: start;
}

.input-container > input{
    border-radius: 10px;
    padding: 2px 10px;
}

