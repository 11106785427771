.container-input{
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 50px;
    max-width: 860px;
    box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.4);
    position: relative;
    transform: translate(0px);
}


.title{
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
}