.container{
    width: fit-content;
    height: fit-content;
    max-height: 200px;
    max-width: 200px;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    /* background-image: url('https://media.istockphoto.com/id/1294890688/it/vettoriale/icona-della-concessionaria-auto-auto-nuove-auto-usate-approvate.jpg?s=612x612&w=0&k=20&c=ixzke5krgBgCjzPGDt56qc9UlNW6Fb8B2RDLsqvVULc=');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    
}

.img{
    max-width: 50%;
    max-height: 50%;
    width: 50%;
    height: 50%;
}

