table {
    border-spacing: 0px;
    width: 90%;
    box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    background: #fff;
    animation: my-animation 0.3s 1;
}

@keyframes my-animation {

    0% {
        transform: translate(-50%, 100px)
    }

    50% {
        transform: translate(-50%, -160px)
    }

    100% {
        transform: translate(-50%, -50%)
    }

}

table td {
    padding: 20px 15px;
    text-align: center;
}

table thead {
    border-radius: 10px 10px 0 0;
}

table thead tr {
    border-radius: 10px 10px 0 0;
}

table thead td {
    color: #fff;
    font-size: 20px;
}

table thead td:first-of-type {
    border-radius: 10px 0 0 0;
}

table thead td:last-of-type {
    border-radius: 0 10px 0 0;
}

table tbody td {
    border-top: 1px solid #f0f0f0;
}

table tbody tr:hover {
    background: rgba(123, 153, 218, 0.1);
}

button {
    margin: 0 10px;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
}

button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    transform: translateY(3px);
}

.td-has-switch{
    position: relative;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 1;
    z-index: 99;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked ~ .slider {
    background-color: green;
  }
  
  input:focus ~ .slider {
    box-shadow: 0 0 1px green;
  }
  
  input:checked ~ .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
